import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { ROUTES } from 'constants/common';

import { Button } from 'components/UI/Button';
import { Wrapper, Title, Subtitle, Text } from 'components/404/styled';

const MaintenanceContent = () => {
  const {
    wpPage: {
      maintenance: { title, subtitle, text },
    },
  } = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "maintenance" }) {
        maintenance {
          title
          subtitle
          text
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Text>{text}</Text>
      <Link to={ROUTES.root}>
        <Button>Back to home</Button>
      </Link>
    </Wrapper>
  );
};

export default MaintenanceContent;
