import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import backgroundImage from 'images/background/background.jpg';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import MaintenanceContent from 'components/Maintenance';

const Maintenance = () => {
  const { wpPage } = useStaticQuery(graphql`
    query {
      wpPage(slug: { in: "maintenance" }) {
        ...MetaData
      }
    }
  `);

  return (
    <Layout backgroundImage={backgroundImage}>
      <SEO metadata={wpPage || {}} />
      <MaintenanceContent />
    </Layout>
  );
};

export default Maintenance;
